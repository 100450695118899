/** @format */

import { useEffect, useState, useRef, createRef } from 'react';
import '../styles/aitutor.css';
import { css } from '@emotion/css';
import globals from '../styles/globals';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid'
import {addNewQuestion} from '../redux/aitutorStateSlice'

/**icon */
import Thumb from '../assets/images/thumbs-up.svg';
import botIcon from "../assets/images/BotIconMedium.png"

/**components */
import Nav from '../components/nav';
import AiSidebarCard from '../components/AiSidebarCard';
import ChatbotTextarea from '../components/ChatbotTextarea';
import AiFeature from '../components/AiFeature';
import { GradientButton } from '../styles/GlobalStyles';
import ChatbotAnswer from '../components/ChatbotAnswer';
import ImageUploadModal from "../components/ImageUploadModal.tsx";
import ImageZoomModal from "../components/ImageZoomModal";


let newAnswer = {
	text: 'Biology is the scientific study of living organisms and their interactions with each other and their environments. It is a broad field that encompasses various sub-disciplines, including genetics, evolution, ecology, physiology, anatomy, microbiology, and botany, among others. Biologists seek to understand the structure, function, behavior, and evolution of living organisms, from the molecular level to the level of entire ecosystems. They study the diversity of life on Earth, ranging from single-celled microorganisms to complex multicellular organisms, and investigate the processes that govern life, such as metabolism, reproduction, and adaptation. The study of biology is essential for understanding the complexities of life, addressing challenges related to human health, agriculture, conservation, and the environment, and advancing scientific knowledge and technology in fields such as biotechnology, medicine, and ecology.',
	videoImage: require('../assets/videoClip/bio0.png'),
}


const AITutor = (props) => {

	const historyData = useSelector(state => state.aitutorState).history
	const popularQuestionsData = useSelector(state => state.aitutorState).popular
	const dispatch = useDispatch()

	const [historyDataGrouped, setHistoryDataGrouped] = useState([]);
	//onClick QA > Display Answer
	const [selectQA, setSelectQA] = useState(null);

	let accordionButtonsRef = useRef(null)
  	let accordionPanelsRef = useRef(null)
	let popularQuestionsButton = createRef()
	let popularQuestionsPanel = createRef()

	const gridStyles = css`
		display:flex;
	`

	const botIconStyle = css`
		width: 100px;
		height: 100px;
		margin: -14px;
`;

  // Translated Image
  const [translatedImage, setTranslatedImage] = useState(null);

	// Image Upload Modal
  const [showImageUploadModal, setShowImageUploadModal] = useState(false);
  const [uploadImageSource, setUploadImageSource] = useState(null);

  // Image Zoom Modal
  const [showImageZoomModal, setShowImageZoomModal] = useState(false);
  const [zoomImageSource, setZoomImageSource] = useState(null);

	useEffect(()=>{
		let byDay = {}
		historyData.forEach((entry, index)=>{
			let resetHours = new Date(entry['date']).setHours(0,0,0,0)
			let d = resetHours.valueOf()
			byDay[d] = byDay[d] ? byDay[d] : []
			byDay[d].push(entry);
		})
		let sortedKeys = Object.keys(byDay).sort((a, b) => b - a);
		let sortedArray = []
		sortedKeys.forEach((key=>{
			sortedArray.push(
				{
					key: key,
					entries : byDay[key]
				}
			)
		}))
		setHistoryDataGrouped(sortedArray)
		accordionButtonsRef.current = sortedArray.map(() => createRef())
  		accordionPanelsRef.current = sortedArray.map(() => createRef())
		//if the selected is not a part of popular questions AND
		//if the selected id does not exist in historyData anymore
		if(selectQA && !popularQuestionsData.some((entry)=>entry.id === selectQA.id)){
			if(!historyData.some((entry)=>entry.id === selectQA.id)){
				handleReset()
			}
		}
		
	},[historyData])

	const handleQuestionClick = (questionData) => {
		setSelectQA(questionData);
	};

	const handleReset = () => {
		setSelectQA(null)
    setTranslatedImage(null);
	}

	const handleNewQuestion = (question) => {
		let newQuestion = {
			question:question,
			type:'history',
			answer: newAnswer,
			date: new Date().valueOf(),
			id: uuid()
		}
		dispatch(addNewQuestion(newQuestion))
		setSelectQA(newQuestion)
	}

	const handleAccordion = (i) => {
		let button, panel;
		if(i === 'popular'){
			button = popularQuestionsButton.current
			panel = popularQuestionsPanel.current
		}else{
			button = accordionButtonsRef.current[i].current
			panel = accordionPanelsRef.current[i].current
		}
		if(panel.getAttribute('hidden') === ''){
			button.setAttribute('aria-expanded', 'true')
			panel.removeAttribute('hidden')
		}else{
			button.setAttribute('aria-expanded', 'false')
			panel.setAttribute('hidden', '')
		}
	}

	const handleDate = (item) => {
		let dateString;
		let todayDate = new Date()
		let yesterdayStarter = new Date()
		let yesterday = yesterdayStarter.setDate(yesterdayStarter.getDate() - 1)
		let yesterdayDate = new Date(yesterday)
		let itemDate = new Date(parseInt(item,10)); 
		if(itemDate.setHours(0,0,0,0) == todayDate.setHours(0,0,0,0)){
			dateString = 'Today'
		}
		else if(itemDate.setHours(0,0,0,0) === yesterdayDate.setHours(0,0,0,0)){
			dateString = 'Yesterday'
		}
		else{
			dateString = new Date(parseInt(item,10)).toString()
		}
		return dateString
	}

	return (
		<>
		{
			showImageUploadModal ?
				<ImageUploadModal
					setShowImageUploadModal={setShowImageUploadModal}
					uploadImageSource={uploadImageSource}
					setUploadImageSource={setUploadImageSource}
          translatedImage={translatedImage}
          setTranslatedImage={setTranslatedImage}
				/> : null
		}

    {
      showImageZoomModal ?
        <ImageZoomModal
          setShowImageZoomModal={setShowImageZoomModal}
          translatedImage={translatedImage}
        /> : null
    }
	
		<div className={gridStyles}>
			<Nav/>
			<div className="aitutor-page">
				<section className="AI-sidebar">
					{historyDataGrouped.map((item, i) => {
						return(
							<div key={i} style={{marginBottom: '16px'}}>
							<div
								style={{display:'flex', alignItems: 'center'}}
							>
								<h1 className="Ai-title">{handleDate(item.key)}</h1>
								<button
									className="expand-button"
									ref={accordionButtonsRef.current[i]}
									style={{ cursor: 'pointer'}}
									onClick={()=>{handleAccordion(i)}}
									aria-expanded={true}
								>
									<svg focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" fill="#60646d"></path></svg>
								</button>
							</div>
							<div
								ref={accordionPanelsRef.current[i]}
							>
								{
									item.entries.map((data, j)=>{
										return(
											<AiSidebarCard 
												key={j}
												data={data}
												handleQuestionClick={handleQuestionClick}
												type='history'
											/> 
										)
									})
								}
							</div>
							</div>
						)
					})}
					<div style={{marginBottom: '16px'}}>
					<div
						style={{display:'flex', alignItems: 'center'}}
					>
						<h1 className="Ai-title">Popular questions</h1>
						<button
							className="expand-button"
							ref={popularQuestionsButton}
							style={{ cursor: 'pointer'}}
							onClick={()=>{handleAccordion('popular')}}
							aria-expanded={true}
						>
							<svg focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" fill="#60646d"></path></svg>
						</button>
					</div>
					<div
						ref={popularQuestionsPanel}
					>
						{
							popularQuestionsData.map((data, index) => {
								return(
									<AiSidebarCard 
										key={index}
										data={data}
										handleQuestionClick={handleQuestionClick}
									/>
								)
							})
						}
					</div>
					</div>
				</section>

				<section className="AI-main">
			
					<div className="chatbot-wrapper">
						<img src={botIcon} className={botIconStyle}/>
						<div className="chatbot-text">
							<h2>
								I'm here to help you better understand the answers to your
								homework questions. When you're ready, make a request or ask a
								question.
							</h2>
						</div>
					</div>

					{
						!selectQA ? 
						<>
							<ChatbotTextarea 
								selectQA={selectQA}
								handleNewQuestion={handleNewQuestion}
								showImageUploadModal={showImageUploadModal}
								setShowImageUploadModal={setShowImageUploadModal}
								uploadImageSource={uploadImageSource}
								setUploadImageSource={setUploadImageSource}
                translatedImage={translatedImage}
                setTranslatedImage={setTranslatedImage}
                showImageZoomModal={showImageZoomModal}
                setShowImageZoomModal={setShowImageZoomModal}
							/>
							<div style={{padding: '48px 24px 0 24px'}}>
								<h1 className="Ai-title">Here's why you should give it a try:</h1>
								<AiFeature
									textColor="black"
									iconType="default"
								/>
							</div>
						</>
						: null
					}

					{
						selectQA ? 
						<>
							<ChatbotAnswer
                questionData={selectQA}
                translatedImage={translatedImage}
                setShowImageZoomModal={setShowImageZoomModal}
              />
							<div className="solved">
								<div className="row">
									<p>Was your question solved ?</p>
									<img src={Thumb} />
									<img
										src={Thumb}
										className="thumb-down"
									/>
								</div>
								<GradientButton onClick={handleReset}>
									Ask new question
								</GradientButton>
							</div>
						</>
						: null
					}

				</section>
			</div>
		</div>
		</>
	);
}
export default AITutor;
