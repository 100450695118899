import React from "react";
import { css } from "@emotion/css";


const container = css`
    display: flex;
    gap: 16px;
    align-items: center;
    cursor: pointer;
    flex-shrink: 0;
`

const progress = css`
    position: relative;
    width: 48px;
    height: 48px;

    svg {
        display: block;
    }

    circle {
        stroke-dasharray:125.664;
        stroke-dashoffset:0.000px;
        stroke: #4f5561;
    }
`
const progressData = css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
`

const ger = css`
    margin: 0;
    padding: 0;
    border: none;
    background: transparent;
    font-size: 1rem;
    line-height: 1;
    text-align: start;
    text-align: left;
    color: #FEFEFE;
    cursor: pointer;

    span {
        color: #01cbb7;
        font-weight: 700;
    }
`


const ExamReadyWidget = props => {
    return (
        <div className={container}>
             <div className={progress}>
                <svg viewBox="22 22 44 44"><circle cx="44" cy="44" r="20" fill="none" strokeWidth="4"></circle></svg>
                <div className={progressData}>?</div>
            </div>
            <button className={ger}>
                Get Exam Ready<br></br>
                <span>Upload syllabus</span>
            </button>
        </div>
    )
}

export default ExamReadyWidget