import { useState, useEffect, useRef } from 'react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import {overlay, SecondaryButton, GradientButton} from '../styles/GlobalStyles';
import {colors} from '../styles/colors';
import FocusTrap from 'focus-trap-react';

import close from "../assets/images/close-32.svg";
import cropIcon from "../assets/images/crop-image.svg";

import ReactCrop, { type Crop } from 'react-image-crop';


import 'react-image-crop/dist/ReactCrop.css';
import { canvasPreview } from './canvasPreview.tsx';
import { useDebounceEffect } from './useDebounceEffect.tsx';


const ImageUploadModal = (props) => {

  const uploadImageSource = props.uploadImageSource;
  const setUploadImageSource = props.setUploadImageSource;

  const uploadButtonRef = useRef(null)
  const imgContainerRef = useRef();

  // For displaying the translated image
  const translatedImage = props.translatedImage;
  const setTranslatedImage = props.setTranslatedImage;

  // Toggle crop mode of modal
  const [showCropMode, setShowCropMode] = useState(false);

  // Image to crop
  const [imgToCrop, setImgToCrop] = useState(null);

  // uploadButtonRef.current.focus();

  const stopBubble = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  const handleUpload = () => {
    // For displaying the translated image
    setTranslatedImage(uploadImageSource);

    // TODO: Clear the uploaded image
    setUploadImageSource(null);

    props.setShowImageUploadModal(false);
  }

  const closeModal = () => {
    props.setShowImageUploadModal(false);
  }

  const handleSetShowCropMode = () => {
    // Manually set the max height of the Crop container to match the parent container
    // TODO: Adjust the value if the window dimensions change
    setCropContainerHeight(imgContainerRef.current.offsetHeight);

    setImgToCrop(uploadImageSource);
    setShowCropMode(true);
  }



  // --------------------------- CROP LOGIC ---------------------


  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const blobUrlRef = useRef('');
  const [crop, setCrop] = useState<Crop>(undefined);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState<number | undefined>(16 / 9);
  const [cropContainerHeight, setCropContainerHeight] = useState(250);

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    // We set the crop when the image loads
    // to force the crop canvas to be drawn immediately.
    // Without this, there is no default crop causing an error
    setCrop({
      unit: '%', // Can be 'px' or '%'
      x: 0,
      y: 0,
      width: 100,
      height: 100
    });
  }

  const handleApplyCrop = async () => {
    const image = imgRef.current
    const previewCanvas = previewCanvasRef.current
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist')
    }

    // This will size relative to the uploaded image
    // size. If you want to size according to what they
    // are looking at on screen, remove scaleX + scaleY
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    )
    const ctx = offscreen.getContext('2d')
    if (!ctx) {
      throw new Error('No 2d context')
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height,
    )
    // You might want { type: "image/jpeg", quality: <0 to 1> } to
    // reduce image size
    const blob = await offscreen.convertToBlob({
      type: 'image/png',
    })

    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current)
    }
    blobUrlRef.current = URL.createObjectURL(blob)

    setTranslatedImage(blobUrlRef.current);
    props.setShowImageUploadModal(false);
  }

  const handleCancelCropMode = () => {
    setShowCropMode(false);
    setCrop(undefined); // Crop must be reset so it re-draws for the next Crop Mode showing
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
      }
    },
    100,
    [completedCrop, scale, rotate],
  )

  const handleEscapeCloseModal = (e) => {
    stopBubble(e);
    if (e.code == "Escape") {
      closeModal();
    }
  }

  const handleEscapeStopCrop = (e) => {
    stopBubble(e);
    if (e.code == "Escape") {
      setShowCropMode(false);
    }
  }

  return(
    <>
      <FocusTrap>
        <div className={uploadModal} onKeyUp={handleEscapeCloseModal}>
          <button className={closeButton} onClick={closeModal} >
            <img src={close}/>
          </button>

          {showCropMode == false ?
            <>
              <p className={modalHeader}>Upload image</p>

              {
                uploadImageSource == null ?
                <p>Something went wrong. There is no image to display.</p>
                :
                <>

                  <div className={imgContainer} ref={imgContainerRef}>
                    <img className={imgPreview} src={uploadImageSource} />
                  </div>


                  <div className={actions}>
                    <TriggerCropButton onClick={handleSetShowCropMode}>
                      <img src={cropIcon} />
                      Crop image
                    </TriggerCropButton>
                    <UploadButton onClick={handleUpload} ref={uploadButtonRef} >
                      Upload
                    </UploadButton>
                  </div>
                </>
              }
            </>
          :
            <>
              <p className={modalHeader}>Crop image</p>

              <div className={imgCropContainer}>
                <ReactCrop
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                  className={reactCropOverrides}
                  style={{maxHeight: cropContainerHeight + 'px'}}
                >
                  <img
                    ref={imgRef}
                    alt="Image to crop"
                    src={imgToCrop}
                    style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>


                {!!completedCrop && (
                  <>
                    {/*This canvas is needed for handleApplyCrop, but is display:none*/}
                    <div style={{
                          position: 'absolute',
                          top: '0',
                          left: '0',
                          display: 'none'
                        }}>
                      <canvas
                        ref={previewCanvasRef}
                        style={{
                          border: '1px solid black',
                          objectFit: 'contain',
                          width: completedCrop.width,
                          height: completedCrop.height,
                        }}
                      />
                    </div>
                  </>
                )}
              </div>

              <div className={actions}>
                <CancelButton onClick={handleCancelCropMode}>
                  Cancel
                </CancelButton>

                <CropButton onClick={handleApplyCrop}>
                  Crop image
                </CropButton>
              </div>
            </>
          }

          </div>
        </FocusTrap>
        <div className={overlay}></div>
    </>
  )
}   

export default ImageUploadModal

const closeButton = css`
  margin: 0;
  padding: 0;
  position:absolute;
  top:24px;
  right:24px;

  img {
    display: block;
  }
`;

const uploadModal = css`
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  justify-content: center;
  min-width:60vw;
  max-width: 80vw;
  height: 80vh;
  padding: 24px;
  top: 50%;
  left: 50%;
  right: unset;
  transform: translate(-50%, -50%);
  background-color:${colors.grayLight3};
  border-radius:12px;
  z-index:3001;
  color:${colors.textlightThemeDefault};
`;

const modalHeader = css`
  margin: 40px 0 32px;
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
  padding: 0;
  flex-grow: 0;
  align-self: flex-start;
`;

const imgContainer = css`
  flex-grow: 1;
  position: relative;
  width: 100%;
`;

const imgPreview = css`
  max-width: 100%;
  max-height: 100%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const imgCropContainer = css`
  flex-grow: 1;
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const uploadImage = css`
  border: 3px solid #F00;
`;

const UploadButton = styled(GradientButton)`
  width:100px;
  margin:0 0 0 auto;

  &:focus{
    outline-color: black;
    outline-offset: 5px;
    outline-style: solid;
  }
`;

const TriggerCropButton = styled(SecondaryButton)`
  border-color: ${colors.darkGray};
  color: ${colors.textlightThemeDefault};
  gap: 8px;
  font-weight: 400;
  margin:0;

  &:focus{
    outline-color: black;
    outline-offset: 5px;
    outline-style: solid;
  }
`;

const CropButton = styled(GradientButton)`
  width:100px;
  margin:0 0 0 auto;

  &:focus{
    outline-color: black;
    outline-offset: 5px;
    outline-style: solid;
  }
`;

const CancelButton = styled(SecondaryButton)`
  border-color: ${colors.darkGray};
  color: ${colors.textlightThemeDefault};
  gap: 8px;
  font-weight: 400;
  margin:0;
  min-width: unset;

  &:focus{
    outline-color: black;
    outline-offset: 5px;
    outline-style: solid;
  }
`;

const actions = css`
  margin: 32px 0 0;
  display: flex;
  align-self: stretch;
`;

const reactCropOverrides = css`
  *:focus-visible {
    outline: none;
  }

  .ReactCrop__crop-selection:focus {
    outline-color: #31D0FD;
  }

  .ReactCrop__drag-handle:focus {
    background-color: #31D0FD;
    border-color: #31D0FD;
  }

  .ReactCrop__crop-mask > rect {
    fill: #FFF;
    fill-opacity: .35;
  }
`;
