import React from 'react';
import styled from '@emotion/styled';
import { GradientButton } from '../styles/GlobalStyles';
import Background from '../assets/tooltip-bg.png';
import { Link } from 'react-router-dom';

import botIcon from "../assets/images/BotIconLarge.png"

const ChatbotTooltip = (props) => {
	const handleClose = (e) => {
		e.stopPropagation();
		props.onClose();
		// console.log('click')
	};

	return (
		<TooltipBox>
			<div className="contents">
				<button
					className="closeButton"
					onClick={handleClose}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
					>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858Z"
							fill="#05112A"
						/>
					</svg>
				</button>
				<div className="left">
					<h1>Feeling stuck?</h1>
					<p>
						I’m here to help with your trickiest homework questions. Share them
						to get step-by-step explanations and video lessons from expert
						tutors.
					</p>
					<Link to={process.env.PUBLIC_URL +'/aitutor'}>
						<GradientButton>Try it out</GradientButton>
					</Link>
					
				</div>
				<div className="right" style={{pointerEvents:'none'}}>
					{/* <Chatbot
						outerRingStyle={{ width: '175px', height: '175px' }}
						style={{ top: '10%', left: '70%' }}
					/> */}
					<img src={botIcon} style={{width:'137px', height: '143px'}}/>
				</div>
			</div>
		</TooltipBox>
	);
};

export default ChatbotTooltip;

const TooltipBox = styled.div`
	z-index: 999;
	position: absolute;

	div.contents {
		background-image: url(${Background});
		background-size: cover;
		background-repeat: no-repeat;
		width: 700px;
		height: 280px;
		border-radius: 25px;
		padding: 40px 48px;
		position: absolute;
		top: 120px;
		left: 150px;
		display: flex;
		flex-direction: row;

		/**Arrow*/
		::after {
			content: '';
			position: absolute;
			top: 50%;
			right: 100%;
			margin-top: -30px;
			border-width: 12px;
			border-style: solid;
			border-color: transparent #fefefe transparent transparent;
		}
		.closeButton {
			position: absolute;
			top: 24px;
			right: 24px;
			background: none;
			border: none;
			color: black;
			font-size: 20px;
			cursor: pointer;
		}
	}
	.left {
		flex-basis: 70%;

		h1 {
			color: var(--Mojo-primary-indigo, #05112a);
			font-family: 'TT Commons';
			font-size: 1.5rem;
			font-weight: 700;
			line-height: 1.75rem;
			letter-spacing: 0.025rem;
			text-align: left;
			padding: 0;
			margin: 0;
		}
		p {
			color: #333;
			font-family: 'TT Commons';
			font-size: 1.125rem;
			font-weight: 500;
			line-height: 1.5rem;
			letter-spacing: 0.025rem;
			text-align: left;
			padding: 0;
			margin: 8px 0 20px 0;
		}
	}
	.right {
		flex-basis: 30%;
		#chatbot-lg {
			.outer-ring {
				width: 8.5rem;
			}
		}
	}
`;
