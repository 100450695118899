import { useState, useEffect, useRef } from "react";
import { css } from "@emotion/css";
import styled from '@emotion/styled'
import {overlay, SecondaryButton, GradientButton} from "../styles/GlobalStyles"
import {colors} from "../styles/colors"
// import { useSelector, useDispatch } from 'react-redux';
// import { uploadSyllabus } from "../redux/practiceStateSlice";
import FocusTrap from "focus-trap-react"

import close from "../assets/images/close-32.svg";
import cropIcon from "../assets/images/crop-image.svg";

const ImageZoomModal = (props) => {

    const translatedImage = props.translatedImage;

    const stopBubble = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const closeModal = () => {
        props.setShowImageZoomModal(false);
        // TODO Remove ESC key event listener
    }

    return(
      <>
        <FocusTrap>
          <div className={zoomModal}>
            <button
                className={closeButton}
                onClick={closeModal}
            >
              <img src={close}/>
            </button>

            {
              translatedImage == null ?
                <p>Something went wrong. There is no image to display.</p>
              :
                <>
                  <div className={actions}>
                      <button
                        className={zoomBtn}
                        onClick={()=>{alert('Not implemented yet.')}}
                      >
                        <ZoomInIcon />
                      </button>

                      <button
                        className={zoomBtn}
                        onClick={()=>{alert('Not implemented yet.')}}
                      >
                        <ZoomOutIcon />
                      </button>
                  </div>
                  <div className={imgContainer} style={{backgroundImage: 'url(' + translatedImage + ')'}}>
                  </div>
                </>
            }
          </div>
        </FocusTrap>
        <div className={overlay}></div>
      </>
    )
}   

export default ImageZoomModal


const ZoomInIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={zoomIcons}>
      <g clip-path="url(#clip0_29_3023)">
        <path d="M13.5604 12.2534L16.7604 15.4534C16.9248 15.6286 17.0145 15.8609 17.0107 16.1012C17.0068 16.3414 16.9096 16.5708 16.7396 16.7406C16.5696 16.9104 16.3402 17.0075 16.1 17.0112C15.8597 17.0148 15.6275 16.9249 15.4524 16.7604L12.2524 13.5604C10.8414 14.6394 9.07296 15.1417 7.30544 14.9655C5.53792 14.7892 3.90351 13.9476 2.7334 12.6112C1.56329 11.2747 0.945009 9.54346 1.00384 7.76814C1.06268 5.99283 1.79423 4.30628 3.05026 3.05026C4.30628 1.79423 5.99283 1.06268 7.76814 1.00384C9.54346 0.945009 11.2747 1.56329 12.6112 2.7334C13.9476 3.90351 14.7892 5.53792 14.9655 7.30544C15.1417 9.07296 14.6394 10.8414 13.5604 12.2524V12.2534ZM8.00035 13.1504C9.36622 13.1504 10.6761 12.6078 11.642 11.642C12.6078 10.6761 13.1504 9.36622 13.1504 8.00035C13.1504 6.63449 12.6078 5.32457 11.642 4.35875C10.6761 3.39294 9.36622 2.85035 8.00035 2.85035C6.63449 2.85035 5.32457 3.39294 4.35875 4.35875C3.39294 5.32457 2.85035 6.63449 2.85035 8.00035C2.85035 9.36622 3.39294 10.6761 4.35875 11.642C5.32457 12.6078 6.63449 13.1504 8.00035 13.1504Z"/>
        <path d="M8.87544 7.125H10.6794C10.9281 7.125 11.1665 7.22377 11.3424 7.39959C11.5182 7.5754 11.6169 7.81386 11.6169 8.0625C11.6169 8.31114 11.5182 8.5496 11.3424 8.72541C11.1665 8.90123 10.9281 9 10.6794 9H8.87544V10.5C8.87544 10.7321 8.78325 10.9546 8.61916 11.1187C8.45506 11.2828 8.2325 11.375 8.00044 11.375C7.76838 11.375 7.54582 11.2828 7.38172 11.1187C7.21763 10.9546 7.12544 10.7321 7.12544 10.5V9H5.32144C5.0781 8.99205 4.84738 8.8898 4.67806 8.71485C4.50873 8.5399 4.41406 8.30597 4.41406 8.0625C4.41406 7.81903 4.50873 7.5851 4.67806 7.41015C4.84738 7.2352 5.0781 7.13295 5.32144 7.125H7.12544V5.5C7.12544 5.26794 7.21763 5.04538 7.38172 4.88128C7.54582 4.71719 7.76838 4.625 8.00044 4.625C8.2325 4.625 8.45506 4.71719 8.61916 4.88128C8.78325 5.04538 8.87544 5.26794 8.87544 5.5V7.125Z"/>
      </g>
      <defs>
        <clipPath id="clip0_29_3023">
          <rect width="18" height="18" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

const ZoomOutIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={zoomIcons}>
      <g clip-path="url(#clip0_29_3028)">
        <path d="M13.5604 12.2534L16.7604 15.4534C16.9248 15.6286 17.0145 15.8609 17.0107 16.1012C17.0068 16.3414 16.9096 16.5708 16.7396 16.7406C16.5696 16.9104 16.3402 17.0075 16.1 17.0112C15.8597 17.0148 15.6275 16.9249 15.4524 16.7604L12.2524 13.5604C10.8414 14.6394 9.07296 15.1417 7.30544 14.9655C5.53792 14.7892 3.90351 13.9476 2.7334 12.6112C1.56329 11.2747 0.945009 9.54346 1.00384 7.76814C1.06268 5.99283 1.79423 4.30628 3.05026 3.05026C4.30628 1.79423 5.99283 1.06268 7.76814 1.00384C9.54346 0.945009 11.2747 1.56329 12.6112 2.7334C13.9476 3.90351 14.7892 5.53792 14.9655 7.30544C15.1417 9.07296 14.6394 10.8414 13.5604 12.2524V12.2534ZM8.00035 13.1504C9.36622 13.1504 10.6761 12.6078 11.642 11.642C12.6078 10.6761 13.1504 9.36622 13.1504 8.00035C13.1504 6.63449 12.6078 5.32457 11.642 4.35875C10.6761 3.39294 9.36622 2.85035 8.00035 2.85035C6.63449 2.85035 5.32457 3.39294 4.35875 4.35875C3.39294 5.32457 2.85035 6.63449 2.85035 8.00035C2.85035 9.36622 3.39294 10.6761 4.35875 11.642C5.32457 12.6078 6.63449 13.1504 8.00035 13.1504Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 7C5.225 7 5 7.225 5 7.5V8.5C5 8.775 5.225 9 5.5 9H10.5C10.775 9 11 8.775 11 8.5V7.5C11 7.225 10.775 7 10.5 7H5.5Z" />
      </g>
      <defs>
        <clipPath id="clip0_29_3028">
          <rect width="18" height="18" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}


const closeButton = css`
  margin: 0;
  padding: 0;
  position:absolute;
  top: 24px;
  right: 24px;

  img {
    display: block;
  }
`;

const zoomModal = css`
  display: flex;
  flex-direction: column;
  position: absolute;
  min-width:60vw;
  max-width: 80vw;
  // max-height: 80vh;
  height: 80vh;
  padding: 20px 24px;
  top: 50%;
  left: 50%;
  right: unset;
  transform: translate(-50%, -50%);
  background-color:${colors.grayLight3};
  border-radius:12px;
  z-index:3001;
  color:${colors.textlightThemeDefault};
`;

const imgContainer = css`
  background-color: #D6D6D6;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  flex-grow: 1;
`;

const actions = css`
  margin: 40px 0 16px;
  display: flex;
  gap: 20px;
  justify-content: center;
  fill: #252525;
  color: #252525;
`;

const zoomBtn = css`
  margin: 0;
  padding: 0;
`;

const zoomIcons = css`
  display: block;
  fill: inherit;
`;
