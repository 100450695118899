import { createSlice, current } from "@reduxjs/toolkit";
import { v4 as uuid } from 'uuid'

let todayDate = new Date()

// let newAnswer = {
//   text: 'Biology is the scientific study of living organisms and their interactions with each other and their environments. It is a broad field that encompasses various sub-disciplines, including genetics, evolution, ecology, physiology, anatomy, microbiology, and botany, among others. Biologists seek to understand the structure, function, behavior, and evolution of living organisms, from the molecular level to the level of entire ecosystems. They study the diversity of life on Earth, ranging from single-celled microorganisms to complex multicellular organisms, and investigate the processes that govern life, such as metabolism, reproduction, and adaptation. The study of biology is essential for understanding the complexities of life, addressing challenges related to human health, agriculture, conservation, and the environment, and advancing scientific knowledge and technology in fields such as biotechnology, medicine, and ecology.',
//   videoImage: require('../assets/videoClip/bio0.png'),
// }

const initialState = {
  popular:[
    {
      question:
        'Which of the following statements concerning dehydration reactions and hydrolysis is correct?',
      answer: {
        text: 'Monomers are building blocks that can be linked together to form polymers.Monomers come from the root of mono, meaning one or singular, and the root of poly, meaning many.Lipids don not use consistent monomers to form polymers.Carbohydrates use saccharides indicated by hexagons in the course. Proteins use amino acids represented by circles.Nucleic acids are built with nucleotides represented by shapes similar to a diamond.Monomers will vary depending on the type of bio molecule polymer that they form.Carbohydrates, proteins and nucleic acids all use consistent monomers.Lipids don not use consistent monomers.Moving forward in the course, monomers and polymers will be discussed more..',
        videoImage: require('../assets/videoClip/bio0.png'),
      },
      id:uuid(),
      type:'popular'
    },
    {
      question:
        'Which molecule most easily diffuses across a biological membrane is lipid bilayer, without help of a protein?',
      answer: {
        text: 'Biological membranes are selectively permeable. Small, uncharged, non-polar molecules can freely diffuse across the membrane and Molecules with polar or hydrophilic components can cross the membrane, but not as easily. Charged or large molecules cannot freely diffuse across the membrane. Gas molecules like oxygen, carbon dioxide and nitrogen can easily diffuse. Water, steroids and glycerol can get through, but not as easily. Large poly peptides or proteins, large polysaccharides and nucleic acids cannot freely diffuse. The table summarizes the molecule characteristics. Practice applying these concepts as we move forward in the course',
        videoImage: require('../assets/videoClip/biology-video.png'),
      },
      id:uuid(),
      type:'popular'
    },
    {
      question:
        'Substrate-level phosphorylation is utilized to create ATP in which steps of aerobic cellular respiration?',
      answer: {
        text: 'Oxidative phosphorylation is the second type of phosphorylation in aerobic cellular respiration.It occurs in a two-step process: electron transport chain and chemiosmosis.Redox reactions take place in the electron transport chain, which provides energy to generate ATP.The electron transport chain builds a hydrogen ion concentration gradient.Chemiosmosis utilizes the concentration gradient to generate ATP.The combination of two processes allows for oxidative phosphorylation.The electron transport chain and chemiosmosis are part of the fourth stage in aerobic cellular respiration.The first three stages are not directly involved with oxidative phosphorylation.Oxidative phosphorylation generates a large amount of ATP.Both processes will be discussed in detail later in the course.',
        videoImage: require('../assets/videoClip/bio2.png'),
      },
      id:uuid(),
      type:'popular'
    },
    {
      question:
        'When glucose donates electrons to NAD+ creating NADH, the glucose molecule becomes?',
      answer: {
        text: 'Redox means oxidation and reduction and Electrons are transferred between molecules in Redox reactions. Oxidation: Losing electrons. Reduction: Gaining electrons. Oxidation and reduction reactions happen simultaneously. Leo the lion goes Ger: Losing electrons is oxidation, gaining electrons is reduction. Oxidation and reduction reactions go hand in hand. Image example of electron transfer. Redox reactions will be further explored in the course. End of introduction to Redox reactions',
        videoImage: require('../assets/videoClip/genetic-video.png'),
      },
      id:uuid(),
      type:'popular'
    },
    {
      question:
        'This statements C6H12O6 + 6 O2 → 6 H2O + Energy describes the results of the following reaction?',
      answer: {
        text: 'Aerobic cellular respiration is a Redox reaction.Redox reaction involves the transfer of electrons between molecules.Glucose is oxidized in the process of aerobic cellular respiration.Oxygen gas is reduced and gains electrons.Oxygen is the final electron acceptor.The main goal of aerobic cellular respiration is to produce ATP.It converts glucose and oxygen into carbon dioxide gas, water, and ATP.Glucose molecule losses electrons, and all electrons are used to generate ATP.Oxygen gas molecule gains electrons and is ultimately converted into water.Professors want students to be familiar with the overall chemical equation for aerobic cellular respiration.',
        videoImage: require('../assets/videoClip/bio1.png'),
      },
      id:uuid(),
      type:'popular'
    }
  ],
  history: [
    {
      question:"What is a chemical reaction?",
      answer: {
        text: 'A chemical reaction is a process in which one or more substances, known as reactants, are converted into one or more different substances, known as products. During a chemical reaction, the bonds between atoms in the reactants are broken, and new bonds are formed to create the products. This often involves the rearrangement of atoms and the exchange of electrons between atoms to form new chemical compounds. Chemical reactions can be represented using chemical equations, which show the reactants on the left side of the equation and the products on the right side. The equation also includes symbols to indicate the physical states of the reactants and products (solid, liquid, gas, or aqueous solution) as well as coefficients to balance the number of atoms of each element on both sides of the equation. Chemical reactions are fundamental to many natural processes, such as photosynthesis, respiration, and digestion, as well as to numerous industrial processes and everyday activities.',
        videoImage: require('../assets/videoClip/bio0.png'),
      },
      date: todayDate.setDate(todayDate.getDate() - 1),
      id: uuid(),
      type:'history'
    },
    // {
    //   problem:"test",
    //   answer: {
    //     text: 'A chemical reaction is a process in which one or more substances, known as reactants, are converted into one or more different substances, known as products. During a chemical reaction, the bonds between atoms in the reactants are broken, and new bonds are formed to create the products. This often involves the rearrangement of atoms and the exchange of electrons between atoms to form new chemical compounds. Chemical reactions can be represented using chemical equations, which show the reactants on the left side of the equation and the products on the right side. The equation also includes symbols to indicate the physical states of the reactants and products (solid, liquid, gas, or aqueous solution) as well as coefficients to balance the number of atoms of each element on both sides of the equation. Chemical reactions are fundamental to many natural processes, such as photosynthesis, respiration, and digestion, as well as to numerous industrial processes and everyday activities.',
    //     videoImage: require('../assets/videoClip/bio0.png'),
    //   },
    //   date: new Date().valueOf()
    // }
  ]
}

const aitutorStateSlice = createSlice({
    name: "aitutorState",
    initialState: initialState,
    reducers : {
        addNewQuestion: (state, action) => {
          if(state.history.every(entry=>entry.id != action.payload.id)){
            state.history.push(action.payload)
          }
          return state;
        },
        deleteQuestion: (state, action) => {
          state.history.forEach((entry, index)=>{
            if(entry.id === action.payload.id){
                state.history.splice(index, 1);
            }
          })
          return state;
        }
    }
});
  
export const { addNewQuestion, deleteQuestion } = aitutorStateSlice.actions;

export default aitutorStateSlice.reducer;