export const colors = {
    textdarkThemeMuted: '#989BA3',
    textdarkThemeDefault: "#FEFEFE",
    textlightThemeDefault: '#020917',
    textdarkThemeLink: '#9B81E2',
    textlightThemeMuted: '#60646D',
    borderdarkThemeborder:'#393F4A',
    borderLightThemeborder: '#DDE3EE',
    backgrounddarkthemebglevel0: '#020917',
    backgrounddarkthemebglevel1: '#161C29',
    backgrounddarkthemebglevel2:'#272D39',
    backgroundlightthemebglevel0: '#FEFEFE',
    baseMonochromeLight: '#FEFEFE',
    primarylightgrey: '#E8EEFA',
    swatchLight400: '#D2D7E2',
    swatchLight300: '#DDE3EE',
    swatchSuccess300: '#0CC0AD',
    swatchSuccess900: '#111E1C',
    swatchDanger300: '#FF8984',
    darkshadow: '#020917',
    blue: '#31B9E1',
    green: '#0CC0AD',
    pink: '#F888AE',
    correct: '#19a292',
    incorrect: '#FFA3A3',
    success: '#01CBB7',
    backgroundGuidedExample: '#900a4b80',
    backgroundGuidedConcept: '#005aad66',
    backgroundProblem: '#094551',
    backgroundCards: '#13203b',
    backgroundCardsHover: '#283758',
    backgroundLightThemeBgLevel0: "#FEFEFE",
    backgroundLightThemeBgLevel1: '#F7F9FD',
    channelsCardsBackground: '#13203B',
    accentTealPrimary: '#01CBB7',
    channelsBackgroundMojoIndigo: "#05112A",
    grayLight: '#e8eefa',
    grayLight3: '#f7f9fd',
    darkGray: '#60646d',
    grayDarkest: '#272d39'
}