/** @format */
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {deleteQuestion} from '../redux/aitutorStateSlice'
import { useSelector, useDispatch } from 'react-redux';

import chat from '../assets/images/chat-icon.svg';
import deleteIcon from '../assets/images/delete-24.svg';


const AiSidebarCard = (props) => {

	const dispatch = useDispatch()

	const [showDelete, setShowDelete] = useState(false)

	const handleDelete = (e) => {
		e.stopPropagation()
		dispatch(deleteQuestion(props.data))
	}

	return (
		<div 
			onClick={props.handleSide}
			onMouseEnter={()=>{setShowDelete(true)}}
			onMouseLeave={()=>{setShowDelete(false)}}
		>
			<Card
				question={props.data.question}
				
			>
				<button 
					onClick={() => props.handleQuestionClick(props.data)}
				>
					<Icon
						src={chat}
						className="chat-icon"
					/>
					<Question>{props.data.question}</Question>
				</button>
				{
					props.type === 'history' ?
					<button
						className='chat-delete'
						style={{
							opacity: showDelete ? '1' : '0'
						}}
						onClick={handleDelete}
					>
						<img src={deleteIcon}/>
					</button>
					: null
				}
			</Card>
		</div>
	);
};

export default AiSidebarCard;

const Card = styled.div`
	border-radius: 8px;
	background: #fff;
	margin: 0.5rem 0;
	padding: 0.5rem;
	display: flex;
	flex-direction: row;
	border: none;
	&:hover {
		box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
	}
	cursor: pointer;
	width:100%;
	justify-content:space-between;
	align-items:center;
	button:first-of-type{
		display:flex;
		margin:0; 
		padding:0;
		flex:1;
		width:100%;
	}
`;

const Question = styled.p`
	color: #434b59;
	font-family: 'TT Commons';
	font-size: 1rem;
	font-weight: 400;
	line-height: 22px;
	padding: 0;
	margin: 0;
	text-align: left;
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	text-wrap: wrap;
	text-overflow: ellipsis;
	width:100%;
`;

const Icon = styled.img`
	width: 1.25rem;
	height: 1.25rem;
	margin-right: 8px;
`;
