/** @format */

import React, { useState, useEffect } from 'react';
import { css } from '@emotion/css';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { colors } from '../styles/colors';

import myCourseIconOff from '../assets/nav/myCourseOutline.svg';
import myCourseIconOn from '../assets/nav/myCourseFilled.svg';
import ChatbotTooltip from './ChatbotTooltip';
import learnIconOff from '../assets/nav/learnOutline.svg';
import learnIconOn from '../assets/nav/learnFilled.svg';
import practiceIconOff from '../assets/nav/examPrepOutline.svg';
import practiceIconOn from '../assets/nav/examPrepFilled.svg';
import aiIconOff from '../assets/nav/aiTutorOutline.svg';
import aiIconOn from '../assets/nav/aiTutorFilled.svg';
import exploreIconOff from '../assets/nav/exploreOutline.svg';
import bookmarksIconOff from '../assets/nav/bookmarksOutline.svg';
import chevron from '../assets/images/previous-24.svg';

const Nav = (props) => {

	const location = useLocation()

	const [mini, setMini] = useState(false);
	const [showTooltip, setShowTooltip] = useState(false);

	useEffect(()=>{
        if(props.showChatPopUp){
            setMini(true)
        }
    },[props.showChatPopUp])

	const navStyles = css`
		width:${mini ? '60px' : '152px'};
		flex-shrink:0;
		position: relative;
		top: 0;

		nav {
			padding: 80px 0 16px;
			height: calc(100vh - 64px);
			box-sizing: border-box;
			background-color: #192a4d;
			box-shadow: 8px 4px 16px 0px rgba(0, 0, 0, 0.24);
		}
	`;

	const leftNav = css`
		display: flex;
		flex-direction: column;
		gap: 32px;
		height: 100%;

		li[role='separator'] {
			height: 1px;
			background-color: #404552;
		}

		li a {
			display: flex;
			align-items: center;
			height: 32px;
			gap: 12px;
			color: var(--text-primary);
			text-decoration: none;
			font-family: TT Commons;
			font-weight: 400;
			font-size: 18px;
			letter-spacing: 0.4px;
			line-height: 24px;
			outline: none;
			position: relative;
			opacity: 0.7;

			&:hover, &.active {
				opacity: 1;
				// font-weight: 500;
			}
			div {
				width: 3px;
				align-self: stretch;
				border-radius: 0 8px 8px 0;
			}
			&.active div{
				background-color:${colors.accentTealPrimary};
			}
		}
	`;
	const push = css`
		margin-top: auto;
	`;

	const miniButton = css`
		width:24px;
		height:24px;
		border-radius: 32px;
		background: var(--Swatch-Light-400, #D2D7E2);
		box-shadow: 4px 2px 16px 0px rgba(0, 0, 0, 0.56);
		position:absolute;
		bottom:100px;
		right:-12px;
		display:flex;
		align-items:center;
		justify-content:center;
		z-index: 100;
		img{
			transform: ${mini ? 'rotate(180deg)' : 'unset'}
		}
		
	`

	return (
		<div className={navStyles}>
			<nav>
				<ul className={leftNav}>
					<li>
						<Link
							// to={process.env.PUBLIC_URL + '/mycourse/65ae95660e075b6be10f9cf5'}
							className={location.pathname.includes('mycourse') ? 'active' : ''}
						>
							<div></div>
							<img
								alt=""
								src={location.pathname.includes('mycourse') ? myCourseIconOn : myCourseIconOff}
							></img>
							{!mini ? <span>My Course</span> : null}
						</Link>
					</li>
					<li role="separator"></li>
					<li>
						<Link
							// to={
							// 	props.showChatBot ?
							// 	process.env.PUBLIC_URL +'/learn/65ae95660e075b6be10f9cf5?chatbot=true'
							// 	:
							// 	process.env.PUBLIC_URL +'/learn/65ae95660e075b6be10f9cf5'
							// }
							className={location.pathname.includes('learn') ? 'active' : ''}
						>
							<div></div>
							<img
								alt=""
								src={location.pathname.includes('learn') ? learnIconOn : learnIconOff}
							></img>
							{!mini ? <span>Learn</span> : null}
						</Link>
					</li>
					<li>
						<Link
							// to={
							// 	props.showChatBot ?
							// 	process.env.PUBLIC_URL +'/practice/65ae95660e075b6be10f9cf5?chatbot=true'
							// 	:
							// 	process.env.PUBLIC_URL +'/practice/65ae95660e075b6be10f9cf5'
							// }
							className={location.pathname.includes('practice') ? 'active' : ''}
						>
							<div></div>
							<img
								alt=""
								src={location.pathname.includes('practice') ? practiceIconOn : practiceIconOff}
							></img>
							{!mini ? <span>Practice</span> : null}
						</Link>
					</li>
					<li>
						<Link
							// to={process.env.PUBLIC_URL + '/aitutor'}
							// onMouseEnter={() => setShowTooltip(true)}
							className={location.pathname.includes('aitutor') ? 'active' : ''}
						>
							<div></div>
							<img
								alt=""
								src={location.pathname.includes('aitutor') ? aiIconOn : aiIconOff}
							></img>
							{!mini ? <span>AI Tutor</span> : null}
						</Link>
					</li>
					
					{
						!location.pathname.includes('aitutor') ?
						<>
							{showTooltip && (
								<ChatbotTooltip onClose={() => setShowTooltip(false)} />
							)}
						</>
						: null
					}
					
					<li role="separator"></li>

					<li className={push}>
						<Link 
							// to={process.env.PUBLIC_URL + '/'}
							>
							<div></div>
							<img
								alt=""
								src={exploreIconOff}
							></img>
							{!mini ? <span>Explore</span> : null}
						</Link>
					</li>
					<li>
						<Link 
							// to={process.env.PUBLIC_URL + '/'}
							>
							<div></div>
							<img
								alt=""
								src={bookmarksIconOff}
							></img>
							{!mini ? <span>Bookmarks</span> : null}
						</Link>
					</li>
				</ul>
			</nav>
			<button 
				className={miniButton}
				onClick={()=>{setMini(!mini)}}
			>
					<img src={chevron}/>
			</button>
			{/* <TocMenu
				open={navOpen}
				openMenu={props.openNav}
			></TocMenu> */}
		</div>
	);
};

export default Nav;
