import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/normalize.css'
import './styles/typography.css'
import styles from './styles/baseline.css'
import { Global } from '@emotion/react'
import { BrowserRouter } from "react-router-dom"
import store from './redux/store';
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Global styles={styles}>
    </Global>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);