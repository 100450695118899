import { Tooltip } from 'react-tooltip'

function Tooltips(props){

    return (
        <>   
           {/* ai chatbot page */}
            <Tooltip anchorSelect=".chat-delete" place="top" className='custom-tooltip-dark'>
                Delete
            </Tooltip>
            
        </>
    )
}

export default Tooltips