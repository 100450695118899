import React from "react";
import { css } from "@emotion/css";
import dropdownSvg from '../assets/drop-down.svg';

const account = css`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    gap: 4px;
    background-color: transparent;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    color: #FEFEFE;
    outline: none;
    position: relative;
    cursor: pointer;

    div {
        border: 2px solid #FEFEFE;
        background-color: #0000;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        
        box-sizing: revert;
    }

    span {
        font-size: 16px;
        line-height: 1;
        font-family: Open Sans,Calibri,Tahoma,sans-serif;
        font-weight: 700;
    }
`

const HeaderAccount = props => {
    return (
        <button className={account}>
            <div>
                <span>HH</span>
            </div>
            <img alt="" src={dropdownSvg}></img>
        </button>
    )
}

export default HeaderAccount