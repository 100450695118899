import { useEffect, useState, useRef, createRef } from 'react';
import '../styles/aitutor.css';
import { css } from '@emotion/css';
import globals from '../styles/globals';

import ReactCrop, { type Crop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import testImg from "../test-image.jpg";

const CropUI = (props) => {

  function MyThing({src}) {
    return (
      <>
      <p>This is my thing</p>
      <img src={src} />
      </>
    )
  }

  function CropperController({ src }) {
    // const [crop, setCrop] = useState() // Without <Crop>
    const [crop, setCrop] = useState<Crop | undefined>(undefined);
    return (
      <ReactCrop crop={crop} onChange={c => setCrop(c)}>
        <img src={src} />
      </ReactCrop>
    )
  }

	return (
		<>
    <h1>Hello</h1>
    <p>This is a test of the React Image Crop module</p>
    <p>Test</p>
    {/*<MyThing
      src={testImg}
     />*/}

    <CropperController src={testImg} />

		</>
	);
}
export default CropUI;
