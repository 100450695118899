import 'react-tooltip/dist/react-tooltip.css'
import Header from './components/header';
import AItutor from './views/AItutor';
import CropUI from './views/CropUI.tsx';
import Tooltips from './components/Tooltips';
import { Route, Routes } from "react-router-dom";

function App() {

  return (
    <main>
      <Tooltips/>
      <Header />
      <Routes>
        <Route path={process.env.PUBLIC_URL + "/ai-tutor"} element={<AItutor/>} />
        <Route path={process.env.PUBLIC_URL + "/crop"} element={<CropUI/>} />
      </Routes>
    </main>
  );
}

export default App;
