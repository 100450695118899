import React from "react";
import { css } from "@emotion/css";
import searchIcon from '../assets/search.svg';

const searchContainer = css`
    position: relative;
    border-radius: 40px;
    border: 2px solid #4f5561;
    height: 40px;
    width: 100%;
    max-width: 500px;

    display: flex;

    input {
        color: #FEFEFE;
        font-family: TT Commons;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        padding: 0 0 0 20px;
        background: none;
        border: none;
        width: 100%;

        ::placeholder {
            color: darkgray;
        }
    }
    button {
        margin-right: 15px;
        cursor: pointer;
        -webkit-appearance: none;
        appearance: none;
        background: none;
        border: none;
        outline-offset: 4px;
        padding: 0;
        outline: none;
        position: relative;

        img {
            width: 20px;
            height: 20px;
        }
    }
`

const SearchBar = props => {
    return (
        <div className={searchContainer}>
            <input placeholder="Search any General Biology-related topic or question" type="search" autoCapitalize="none" spellCheck="false"></input>
            <button>
                <img src={searchIcon} role="presentation" alt="search"></img>
            </button>
        </div>
    )
}

export default SearchBar