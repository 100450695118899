import React from "react";
import { css } from "@emotion/css";
import HeaderMenu from "./headerMenu";
import SearchBar from "./searchBar";
import HeaderAccount from "./headerAccount";
import ExamReadyWidget from "./examReadyWidget";
import logoSvg from '../assets/PearsonLogo.svg';
import logoTextSvg from '../assets/PearsonLogoText.svg';

const headerStyles = css`
    padding: 0 16px;
    background: radial-gradient(circle at 100%,#05112a3d 0%,#8a559c3d 40.63%,#8a559c3d 59.55%,#05112a3d 100%),#05112a;    
    display: flex;
    gap: 40px;
    min-height: 64px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index:99;
`
const logoStyles = css`
    color: #FEFEFE;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-bottom: 4px;
    outline: none;
    position: relative;
`
const logoImg = css`
    width: 40px;
    height: 38px;
    overflow: hidden;
`

const rightStyles = css`
    flex: 1 0;
    display: flex;
    align-items: center;
    margin-left: auto;
    justify-content: flex-end;
    gap: 24px;
`

const Header = (props) => {

    return (
        <header className={headerStyles}>
            <div className={logoStyles}>
                <img src={logoSvg} className={logoImg} />
                <img src={logoTextSvg} />
            </div>

            <HeaderMenu></HeaderMenu>
            <div className={rightStyles}>
                <SearchBar></SearchBar>
                <ExamReadyWidget></ExamReadyWidget>
                <HeaderAccount></HeaderAccount>
            </div>
        </header>
    )
}
export default Header