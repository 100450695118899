/** @format */

import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from "@emotion/css";
import IntroImage from '../assets/videoClip/intro.png';

import botIcon from "../assets/images/BotIconMedium.png"

const ChatbotAnswer = (props) => {

  const setShowImageZoomModal = props.setShowImageZoomModal;

	const botIconStyle = css`
		width: 100px;
		height: 100px;
		margin: -14px;
	`;

  const handleShowImageZoomModal = () => {
    setShowImageZoomModal(true)
  }

	return (
		<Container>
      <div className={questionContainer}>
			<Question>
        {props.translatedImage &&


          <div className={attachedImageContainer}>
            <button className={zoomBtn} onClick={handleShowImageZoomModal}>
              <img className={attachedImage} src={props.translatedImage} />
            </button>

          </div>
        }
				{props.questionData && <Text>{props.questionData.question}</Text>}
			</Question>
      </div>

			<div className="chatbot-wrapper">
				<img src={botIcon} className={botIconStyle}/>
				<div className="chatbot-text">
					<h2>
						No problem ! I have a few ways of helping you solve this: <br />
						<br />
						Lets break down the explanation:
					</h2>
					 <AIGenerated>{props.questionData.answer.text}</AIGenerated>
					<Title>
						You can also watch video lessons and similar questions with full
						solutions:
					</Title>

					<Row>
						<VideoCard>
							<VideoClip>
								<Image src={IntroImage} />
							</VideoClip>
							<VideoTitle>Master the concept with video lessons</VideoTitle>
							<Button>Watch video</Button>
						</VideoCard>

						<VideoCard>
							<VideoClip>
								<span>Introduction to Biology</span>
								<Thumbnail src={IntroImage} />
							</VideoClip>
							<VideoTitle>Video solution of a similar question</VideoTitle>
							<Button>See questions</Button>
						</VideoCard>

						<VideoCard>
							<VideoClip>
								<img src={botIcon} className={botIconStyle}/>
							</VideoClip>
							<VideoTitle>
								Solve a similar question with the help of AI tutor
							</VideoTitle>
							<Button>Start solving</Button>
						</VideoCard>
					</Row>
				</div>
			</div>
		</Container>
	);
};
export default ChatbotAnswer;

const Container = styled.div`
	width: 100%;
`;

const questionContainer=css`
  display: flex;
  flex-direction: row;
`;

const Question = styled.div`
	border-radius: 8px;
	border: 1px solid var(--Swatch-Light-300, #dde3ee);
	background: #d8e5ff;
	padding: 10px;
	align-items: center;
  max-width: 80%;
	margin-left: auto;
	margin-bottom: 1rem;
	margin-right: 24px;
`;

const Text = styled.p`
	color: var(--DS-C-2, #000);
	text-align: right;
	font-family: 'TT Commons';
	font-size: 1.125rem;
	font-weight: 400;
	line-height: 1.5rem;
	letter-spacing: 0.015rem;
	padding: 0;
	margin: 0;
	text-align: left;
  white-space: pre-wrap;
`;

const attachedImageContainer = css`
  margin: 0 0 10px;
  padding: 0;
  position: relative;

  display: flex;
  // justify-content: center;
  align-items: center;
`;

const zoomBtn = css`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
  background-color: #D6D6D6;

  max-width: 440px;
  max-height: 345px;
`;

const attachedImage = css`
  display: block;
  max-width: 440px;
  max-height: 345px;
`;

const AIGenerated = styled.p`
	color: var(--Text-lightTheme--Default, #020917);
	font-feature-settings: 'clig' off, 'liga' off;
	font-family: 'TT Commons';
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5rem;
	letter-spacing: 0.015rem;
`;

const Title = styled.h1`
	color: var(--Text-lightTheme--Default, #020917);
	font-family: 'TT Commons';
	font-size: 1.125rem;
	font-weight: 700;
	line-height: 1.5rem;
	letter-spacing: 0.015rem;
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
`;

const VideoCard = styled.div`
	border-radius: 0.5rem;
	border: 1px solid var(--Border-lightTheme--border, #dde3ee);
	background: var(--Text-darkTheme--Default, #fefefe);
	width: 13.25rem;
	padding: 0.5rem;
	flex-direction: column;
`;

const VideoClip = styled.div`
	width: 100%;
	height: 6.625rem;
	padding: 1rem 2rem;
	margin-bottom: 1.5rem;
	justify-content: center;
	border-radius: 0.5rem;
	background: var(--background-light-theme-bg-level-2, #e8eefa);
	display: flex;
	flex-direction: column;
	justify-content: center;
	span {
		color: var(--Text-lightTheme--Default, #020917);
		text-align: center;
		font-family: 'TT Commons';
		font-size: 0.875rem;
		font-weight: 400;
		margin-bottom: 0.5rem;
		letter-spacing: 0.015rem;
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		text-wrap: wrap;
		text-overflow: ellipsis;
	}
`;

const Thumbnail = styled.img`
	width: 3.0625rem;
	height: 1.8125rem;
	border-radius: 0.25rem;
	background: linear-gradient(
			0deg,
			rgba(5, 17, 42, 0.2) 0%,
			rgba(5, 17, 42, 0.2) 100%
		),
		lightgray 50% / cover no-repeat;
	align-self: center;
`;
const Image = styled.img`
	width: 7.9375rem;
	height: 4.625rem;
	border-radius: 0.25rem;
`;

const VideoTitle = styled.h6`
	color: var(--Text-lightTheme--Default, #020917);
	font-family: 'TT Commons';
	font-size: 1.125rem;
	font-weight: 700;
	line-height: 1.5rem;
	letter-spacing: 0.015rem;
	padding: 0;
	margin: 0;
`;

const Button = styled.button`
	border-radius: 2.5rem;
	border: 2px solid var(--Swatch-Dark-800, #272d39);
	padding: 0.5rem 1rem;
	justify-content: center;
	font-family: 'TT Commons';
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1rem;
	letter-spacing: 0.025rem;
	margin-top: 0.7rem;
	cursor: pointer;
`;
