/** @format */

import React, { useEffect, useState, useRef } from 'react';
import styled  from '@emotion/styled';
import { css } from "@emotion/css";
import {colors} from "../styles/colors"

import warningIcon from "../assets/images/warning-24.svg";
import close from "../assets/images/close-32.svg";
import closeError from "../assets/images/close-24.svg";

import micIcon from "../assets/images/mic-26.svg";
import uploadIcon from "../assets/images/upload-26.svg";
import dropToUploadIcon from "../assets/images/drop-to-upload.svg";


const ChatbotTextarea = (props) => {

	const showImageUploadModal = props.showImageUploadModal;
	const setShowImageUploadModal = props.setShowImageUploadModal;

  const setShowImageZoomModal = props.setShowImageZoomModal;

	const uploadImageSource = props.uploadImageSource;
	const setUploadImageSource = props.setUploadImageSource;

	const inputRef = useRef();
	
	const [inputValue, setInputValue] = useState('');
	const [errorTooMany, setErrorTooMany] = useState(false);
	const [errorWrongFormat, setErrorWrongFormat] = useState(false);

  // For displaying the translated image
  const translatedImage = props.translatedImage;
  const setTranslatedImage = props.setTranslatedImage;

	/**display text on textarea */
	const handleChange = (e) => {
		setInputValue(e.target.value);
		if (typeof props.onChange === 'function') {
			props.onChange(e.target.value);
		}
	};

  const handleKeyDown = (e) => {
    console.debug(e.key);
    if (e.key === "f" && (e.ctrlKey || e.metaKey)) {
      e.target.value = e.target.value + 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc in nisi ante. Morbi luctus neque in nisi dictum tempor. Etiam dignissim accumsan est faucibus viverra. Aliquam eu convallis urna, nec tempus purus. Nam at dui vestibulum, egestas nulla ut, condimentum tellus. Sed condimentum diam metus, quis sollicitudin turpis bibendum nec.'
      setInputValue(e.target.value)
    }
  }

	const handlePaste = (e) => {
		retrieveImageFromClipboardAsBase64(e, function(imageDataBase64) {
			// If there's an image, add to page
			if(imageDataBase64){
				setUploadImageSource(imageDataBase64);
				setShowImageUploadModal(true);
			}
		});
	}

  const [dragging, setDragging] = useState(false);

	const handleDragOver = (e) => {
		e.stopPropagation();
		e.preventDefault();
		e.dataTransfer.dropEffect = 'copy';
    setDragging(true);
	}

  const handleDragLeave = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setDragging(false);
  }

  const handleDragEnd = (e) => {
    setDragging(false);
  }

  const detachImage = (e) => {
    setTranslatedImage(null);
  }


	const handleDrop = (e) => {
		e.preventDefault(); // Keeps file path from being added to textbox
		e.stopPropagation();

    // Clear errors
    setErrorTooMany(null);
    setErrorWrongFormat(null);

		if (e.type === 'drop') {
      let dt = e.dataTransfer;
      let files = dt.files;

      setDragging(false);

			if (files.length == 1) {
				let file = files[0];

				let fileTypes = ['image/jpeg', 'image/png', 'image/webp'];

				if (fileTypes.includes(file.type)) {
					const reader = new FileReader();
					reader.addEventListener('load', (event) => {
						let x = event.target.result;
						// img.src = event.target.result;
						setUploadImageSource(x);
						setShowImageUploadModal(true);
					});
					reader.readAsDataURL(file);
				} else {
					setErrorWrongFormat(true);
				}

			} else {
				// Set error to true
				setErrorTooMany(true);
			}
    } else {
        setUploadImageSource(URL.createObjectURL(e.target.files[0]));
        setShowImageUploadModal(true);

        inputRef.current.value = '';
    }
	}

	/**Submit Icon button */
	const handleSubmit = () => {
		props.handleNewQuestion(inputValue)
		setInputValue(''); //clear text when submit
		// if (inputValue.toLowerCase().includes('')) {
		// 	// setDisplayedValue(inputValue);
		// }
	};

	const handleShowImageUploadModalInParent = (x) => {
		setShowImageUploadModal(true);
	}

  const handleShowImageZoomModal = () => {
    setShowImageZoomModal(true)
  }

	function retrieveImageFromClipboardAsBase64(pasteEvent, callback, imageFormat){
	    if(pasteEvent.clipboardData == false){
	        if(typeof(callback) == "function"){
	            callback(undefined);
	        }
	    };

	    // retrive elements from clipboard
	    var items = pasteEvent.clipboardData.items;

	    if(items == undefined){
	        if(typeof(callback) == "function"){
	            callback(undefined);
	        }
	    };
	    // loop the elements
	    for (var i = 0; i < items.length; i++) {
	        // Skip content if not image
	        if (items[i].type.indexOf("image") == -1) continue;
	        // Retrieve image on clipboard as blob
	        var blob = items[i].getAsFile();

	        // Create an abstract canvas and get context
	        var mycanvas = document.createElement("canvas");
	        var ctx = mycanvas.getContext('2d');

	        // Create an image
	        var img = new Image();

	        // Once the image loads, render the img on the canvas
	        img.onload = function(){
	            // Update dimensions of the canvas with the dimensions of the image
	            mycanvas.width = this.width;
	            mycanvas.height = this.height;

	            // Draw the image
	            ctx.drawImage(img, 0, 0);

	            // Execute callback with the base64 URI of the image
	            if(typeof(callback) == "function"){
	                callback(mycanvas.toDataURL(
	                    (imageFormat || "image/png")
	                ));
	            }
	        };

	        // Crossbrowser support for URL
	        var URLObj = window.URL || window.webkitURL;

	        // Creates a DOMString containing a URL representing the object given in the parameter
	        // namely the original Blob
	        img.src = URLObj.createObjectURL(blob);
	    }
	}

	return (
		<>
			<Container
				onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDragEnd={handleDragEnd}
				onDrop={handleDrop}
        className= {`${dragging == true ? 'dragging' : ''} ${errorTooMany == true || errorWrongFormat == true ? 'error' : ''}`}
			>

        {
          translatedImage != null ?
            <div className={attachedImageContainer}>
              <button className={zoomBtn} onClick={handleShowImageZoomModal} autoFocus>
                <img className={attachedImage} src={translatedImage} />
              </button>

              <button
                  className={detachImageButton}
                  onClick={detachImage}
              >
                  <img src={close}/>
              </button>
            </div>
          :
            null
        }

				<Textarea
					style={props.style}
					placeholder="For example: Balance each given chemical reaction: Ca3N2(s) + H2O(aq) → Ca(OH)2(aq) + NH3(g)"
					value={inputValue}
					onChange={handleChange}
					onPaste={handlePaste}
          onKeyDown={handleKeyDown}
				/>



				<div className={actions}>

					<button type="button" className={actionBtn} onClick={()=>{
            inputRef.current.click()}
          }><img src={uploadIcon} /></button>

					<div className={divider}></div>

					<button type="button" className={actionBtn} onClick={()=>{alert('Not implemented yet.')}}><img src={micIcon} /></button>

					<GradientIconButton onClick={handleSubmit} disabled={inputValue == '' && translatedImage == null}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
						>
							<path
								d="M1 21V3L23 12L1 21ZM3.31579 17.625L17.0368 12L3.31579 6.375V10.3125L10.2632 12L3.31579 13.6875V17.625Z"
								fill="#FEFEFE"
							/>
						</svg>
					</GradientIconButton>
				</div>

        <div className='targetMessage'>
          <img src={dropToUploadIcon} />
          <p>Drop your image here or choose from your computer</p>
        </div>
			</Container>

      {/*Hidden input controller*/}
			<input
          type="file"
          tabindex="-1"
          ref={inputRef}
          style={{display: 'none'}}
          accept="image/webp, image/png, image/jpeg"
          onChange={handleDrop}
      />

      {errorTooMany ?
      	<div className={error}>
        	<img src={warningIcon}/>
        	<div>
          	<h3>Can”t upload multiple files at one time</h3>
          	<p>Please upload one file at a time.</p>
        	</div>
        	<img
        		src={closeError}
        		onClick={()=> setErrorTooMany(false)}
        	/>
        </div>
      :
        null
    	}

    	{errorWrongFormat ?
    		 <div className={error}>
          	<img src={warningIcon}/>
          	<div>
            	<h3>Invalid or unsupported file</h3>
            	<p>Valid file formats: image/jpeg, image/png, image/webp.</p>
          	</div>
          	<img
          		src={closeError}
          		onClick={()=> setErrorWrongFormat(false)}
          	/>
          </div>
    	:
    		null
    	}

			<p className={`${helpText} ${dragging == true ? 'dragging': ''}`}>
				Add an image by drag-and-drop, pasting, or uploading from <a href="#" onClick={()=>{inputRef.current.click()}}>computer</a>.
			</p>
			</>
	);
};

export default ChatbotTextarea;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
  gap: 8px;

  box-sizing: border-box;
  position: relative;

	margin: 0 24px 0 24px;
	padding:24px;
	border: 1px solid #DDE3EE;
	border-radius: 8px;

	&:focus-within {
		border: 1px solid #7f6efa;
		box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.08);
		outline: -webkit-focus-ring-color auto 1px;	
	}

  .targetMessage {
    display: none;
    margin: 0;
    padding: 16px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px dashed #DADEEB;
    background-color: #E7EAF9;
    border-radius: inherit;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    pointer-events: none;

    p {
      margin: 16px 0 0;
      color:${colors.textlightThemeDefault};
      font-size: 1.125rem;
      line-height: 1.5;
    }

    img {
      pointer-events: none;
    }
  }

  &.error {
    border: 1px solid ${colors.swatchDanger300};

    &:focus-within {
      border-color: ${colors.swatchDanger300};
      outline: ${colors.swatchDanger300} auto 1px;
    }
  }

  &.dragging {
    border-color: transparent;

    &:focus-within {
      border-color: transparent;
      box-shadow: none;
      outline: none;
    }

    &.error {
      outline: none;
    }

    .targetMessage {
      display: flex;
    }
  }
`;

const attachedImageContainer = css`
  margin: 0;
  padding: 0;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const zoomBtn = css`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
  min-width: 40px;
  min-height: 40px;
`;

const attachedImage = css`
  display: block;
  max-width: 440px;
  max-height: 345px;
`;

const detachImageButton = css`
    margin: 0;
    padding: 0;
    position:absolute;
    top:4px;
    right:4px;
    background-color: #C7CCD6;
    border-radius: 40px;

    img {
      display: block;
    }
`;

const Textarea = styled.textarea`
  display: block;
	margin-top: 1rem;
	width: 100%;
	min-height: 13rem;
	border-radius: 0.5rem;
	border: none;
	background: #FFF;
	margin: 0;
	padding: 0;
	color: #000;
	font-family: 'TT Commons';
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
	letter-spacing: 0.015rem;
	resize:none;
  align-self: stretch;

	&:focus-visible {
		outline: none;
	}
`;

const GradientIconButton = styled.button`
  margin-left: 11px;
	margin-top: 0;
	margin-right: 0;
	align-self: flex-end;
	border-radius: 40px;
	padding: 8px;
	background: var(
		--Gradient-01,
		linear-gradient(135deg, #e49d2d 0%, #ea0b7e 51.09%, #851b4a 100%)
	);
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

  &:disabled {
    cursor: auto;
    opacity: 0.32;
  }
`;

const error = css`
	margin: 8px 24px 0;
	padding: 8px;
	background-color: #FFDAD6;
	color:${colors.textlightThemeDefault};
	display: flex;
	flex-direction: row;
  border-radius: 4px;
	gap: 8px;
	align-items: center;

	h3, p {
		margin: 0;
		font-size: 0.875rem;
		line-height: 1rem;
	}

	div {
		flex-grow: 1;
	}
`;

const helpText = css`
    margin: 8px 24px 0;
    padding: 0;
    color:${colors.darkGray};

    &.dragging {
      opacity: 0.5;
    }

    a {
      color: #655591;
      text-decoration: underline;
    }
`;

const actions = css`
  margin: 0;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	gap: 3px;

	img {
		display: block;
	}
`;

const divider = css`
		margin: 0 6px;
		padding: 0;
		align-self: stretch;
		border-left: 1px solid #B4B7B7;
	`;

const actionBtn = css`
	display: block;
	margin: 0;
	padding: 6px;
	border-radius: 100%;

	&:hover {
		background-color: #ECEBEF;
	}
`;
