/** @format */

import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

/**icon */
import list from '../assets/images/list-black.svg';
import video from '../assets/images/video-black.svg';
import videoAnswer from '../assets/images/video-answer-black.svg';
import gpt4 from '../assets/images/gpt4-black.svg';
import listWhite from '../assets/images/list-white.svg';
import videoWhite from '../assets/images/video-white.svg';
import videoAnswerWhite from '../assets/images/video-answer-white.svg';
import gpt4White from '../assets/images/gpt4-white.svg';

const AiFeature = (props) => {
	const featureData = [
		{
			icon: {
				default: list,
				second: listWhite,
			},
			title: 'Step by step guidance',
			description: 'Step by step guidance on how to solve',
		},
		{
			icon: {
				default: video,
				second: videoWhite,
			},
			title: 'Video lessons',
			description: 'Master the concept with video lessons.',
		},
		{
			icon: {
				default: videoAnswer,
				second: videoAnswerWhite,
			},
			title: 'Video answers',
			description: 'Similar questions with video answers',
		},
		{
			icon: {
				default: gpt4,
				second: gpt4White,
			},
			title: 'GPT 4',
			description: 'Superior understanding and nuanced responses.',
		},
	];

	return (
		<Row style={props.style}>
			{featureData.map((feature, index) => (
				<CardContainer key={index}>
					<Card>
						<Icon src={feature.icon[props.iconType]} />
						<Title textColor={props.textColor}>{feature.title}</Title>
						<Description textColor={props.textColor}>
							{feature.description}
						</Description>
					</Card>
					{
						index != featureData.length - 1 ?
						<Divider />
						: null
					}
					
				</CardContainer>
			))}
		</Row>
	);
};

export default AiFeature;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 0.75rem;
	// padding:24px;
`;

const CardContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 23%;
	// padding-right: 24px;
	align-items: center;
`

const Card = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	// padding: 1rem;
	align-items: center;
	&:hover {
		img {
			transform: scale(1.2);
		}
	}
`;
const Icon = styled.img`
	width: 2rem;
	height: 2rem;
`;

const Title = styled.h6`
	color: ${(props) => props.textColor};
	text-align: center;
	font-family: 'TT Commons';
	font-size: 1.125rem;
	font-weight: 500;
	line-height: 1.5rem;
	letter-spacing: 0.015rem;
	padding: 0;
	margin: 0.75rem 0 1.5rem 0;
`;

const Description = styled.p`
	color: ${(props) => props.textColor};
	text-align: center;
	font-family: 'TT Commons';
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1rem;
	letter-spacing: 0.015rem;
	padding: 0;
	margin: 0;
`;
const Divider = styled.div`
	border: 1px solid rgba(226, 230, 233, 0.9);
	height: 3.75rem;
	align-self: center;
	margin-left: 16px;
`;
